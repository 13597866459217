<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        If a reaction has an activation energy,
        <stemble-latex content="$\text{E}_\text{a},$" />
        of
        <number-value :value="activationEnergy" unit="\text{kJ/mol,}" />
        what temperature would be required to make the reaction occur
        <latex-number :number="nFoldIncrease.toFixed(2)" />
        times faster than it did at
        <number-value :value="Temp1" unit="\text{K?}" />
      </p>

      <calculation-input
        v-model="inputs.Temp2"
        prepend-text="$\text{T:}$"
        append-text="$\text{K}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '@/tasks/components/displayers/LatexNumber';

export default {
  name: 'Question99',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Temp2: null,
      },
    };
  },
  computed: {
    activationEnergy() {
      return this.taskState.getValueBySymbol('activationEnergy').content;
    },
    Temp1() {
      return this.taskState.getValueBySymbol('Temp1').content;
    },
    nFoldIncrease() {
      return this.taskState.getValueBySymbol('nFoldIncrease').content.toFloat();
    },
  },
};
</script>
